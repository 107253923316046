import { formatMoney } from './currency';
import { createSlug } from './urlHelpers';

export const createListingURL = (id, title) => {
  const slug = createSlug(title);

  return `${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${slug}/${id}`;
};

export const formatListingForExport = (
  listing,
  intl,
  includeImage,
  imageVariant = 'landscape-crop2x'
) => {
  const {
    id: { uuid },
    author,
    attributes: {
      title,
      description,
      price,
      publicData: { location, ...rest },
    },
    images,
  } = listing;

  const notAvailable = 'n/a';

  const imageURLMaybe = includeImage
    ? {
        imageURL:
          images.length > 0 ? images[0].attributes.variants[imageVariant].url : notAvailable,
      }
    : {};

  return {
    id: uuid,
    user: author.attributes.profile.displayName,
    title,
    description,
    price: price ? formatMoney(intl, price) : notAvailable,
    location: location
      ? `${location.building ? `${location.building}, ` : ''}${location.address}`
      : notAvailable,
    propertyURL: createListingURL(uuid, title),
    ...imageURLMaybe,
    ...rest,
  };
};

// Expects an array of objects.
export const exportDataToCSV = (data, csvName = 'Borrowed') => {
  const header = Object.keys(data[0]).join(',');

  const csvContent =
    `data:text/csv;charset=utf-8,${header},\n` +
    data
      .map(d =>
        Object.values(d)
          .map(n => (Array.isArray(n) ? `"${n.join(',')}"` : typeof n === 'string' ? `"${n}"` : n))
          .join(',')
      )
      .join('\n');

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${csvName}.csv`);

  document.body.appendChild(link); // Required for FF

  link.click();
};
