import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  PaginationLinks,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LandingFooter,
  ListingsTable,
  NamedLink,
  PrimaryButton,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { SearchForm } from '../../forms';
import {
  closeListing,
  openListing,
  discardListing,
  manualLoadData,
} from './ManageListingsPage.duck';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createListingURL, exportDataToCSV } from '../../util/export';
import { parse } from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

import css from './ManageListingsPage.module.css';

const { Money } = sdkTypes;

const getCategoryInfo = (categoryOptions, key) => {
  return categoryOptions.find(c => c.key === key);
};

export const ManageListingsPageComponent = props => {
  const {
    isAuthenticated,
    closingListing,
    closingListingError,
    listings,
    timeslots,
    onCloseListing,
    onOpenListing,
    openingListing,
    openingListingError,
    onDiscardListing,
    discardListing,
    discardListingError,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled,
    intl,
    history,
    onGetListings,
    currentUser,
    location,
  } = props;
  const { $searchString, page } = parse(location.search);

  const [listingMenuOpen, setListingMenuOpen] = useState(null);

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

  const onToggleMenu = listing => {
    setListingMenuOpen(listing);
  };

  const hasPaginationInfo = useMemo(() => !!pagination && pagination.totalItems !== null, [
    pagination,
  ]);
  const listingsAreLoaded = useMemo(() => !queryInProgress && hasPaginationInfo, [
    queryInProgress,
    hasPaginationInfo,
  ]);

  const loadingResults = useMemo(
    () => (
      <h2>
        <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
      </h2>
    ),
    []
  );

  const queryError = useMemo(
    () => (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    ),
    []
  );

  const noResults = useMemo(
    () =>
      listingsAreLoaded && listings.length === 0 ? (
        <div>
          <h2>
            <FormattedMessage id="ManageListingsPage.noResults" />
          </h2>
          <NamedLink name="NewListingPage" className={css.linkAsButton}>
            <FormattedMessage id="ManageListingPage.newListing" />
          </NamedLink>
        </div>
      ) : null,
    [listingsAreLoaded, listings.length]
  );

  const heading = useMemo(
    () => (
      <h1 className={css.title}>
        <FormattedMessage id="ManageListingsPage.youHaveListings" />
      </h1>
    ),
    []
  );

  const initialSearchFormValues = useMemo(
    () => (queryParams ? { keywords: queryParams.$searchString } : {}),
    [queryParams]
  );

  const paginationLinks = useMemo(
    () =>
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ManageListingsPage"
          pageSearchParams={{ page }}
          pagination={pagination}
        />
      ) : null,
    [listingsAreLoaded, pagination, page]
  );

  const closingErrorListingId = !!closingListingError && closingListingError.listingId;
  const openingErrorListingId = !!openingListingError && openingListingError.listingId;
  const discardErrorListingId = !!discardListingError && discardListingError.listingId;

  const title = useMemo(() => intl.formatMessage({ id: 'ManageListingsPage.title' }), [intl]);

  const onSearchListings = useCallback(
    values => {
      const { keywords } = values;

      const searchParams = { page, $searchString: keywords };

      history.push(
        createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, searchParams)
      );
    },
    [page, history]
  );

  const onClearSearch = useCallback(() => {
    history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, {}));
  }, [history]);

  const handleExport = useCallback(() => {
    const listingsToExport = listings.map(l => {
      const { sharetribeId, author, title, description, price, publicData, metadata } = l;

      const { location, category, pricePerRate } = publicData || {};

      const { pricePerHour, pricePerDay, pricePerWeek } = pricePerRate || {};

      const { transactionCount } = metadata || {};

      const categoryLabel = getCategoryInfo(categoryOptions, category);

      const pricePerHourAsMoney = pricePerHour
        ? new Money(pricePerHour, price.currency || 'USD')
        : null;

      const pricePerDayAsMoney = pricePerDay
        ? new Money(pricePerDay, price.currency || 'USD')
        : null;
      const pricePerWeekAsMoney = pricePerWeek
        ? new Money(pricePerWeek, price.currency || 'USD')
        : null;

      return {
        Owner: `${author.firstName} ${author.lastName}`,
        'Listing name': title,
        'Listing description': description,
        Category: categoryLabel ? categoryLabel.label : 'n/a',
        'Hourly Price': pricePerHourAsMoney ? formatMoney(intl, pricePerHourAsMoney) : 'n/a',
        'Daily Price': pricePerDayAsMoney ? formatMoney(intl, pricePerDayAsMoney) : 'n/a',
        'Weekly Price': pricePerWeekAsMoney ? formatMoney(intl, pricePerWeekAsMoney) : 'n/a',
        Location: location
          ? `${location.building ? `${location.building}, ` : ''}${location.address}`
          : 'n/a',
        'Listing Url': createListingURL(sharetribeId, title),
        Bookings: transactionCount || 'n/a',
      };
    });

    exportDataToCSV(listingsToExport, 'Borrowed - My Listings');
  }, [listings, intl, categoryOptions]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      onGetListings({ sharetribeAuthorId: currentUser.id.uuid, $searchString, $page: page });
    }
  }, [onGetListings, currentUser, $searchString, page]);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ManageListingsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {queryListingsError ? queryError : null}
          <div className={css.listingPanel}>
            <div className={css.headerWrapper}>
              {heading}

              <SearchForm
                className={css.listingSearch}
                onSubmit={onSearchListings}
                initialValues={initialSearchFormValues}
                placeHolder={intl.formatMessage({ id: 'ManageListingsPage.searchListings' })}
                onClear={onClearSearch}
                inProgress={queryInProgress}
              />

              <PrimaryButton
                disabled={listings.length === 0}
                className={css.exportButton}
                onClick={handleExport}
              >
                {intl.formatMessage({ id: 'ManageListingsPage.exportToCSV' })}
              </PrimaryButton>
            </div>
            {queryInProgress ? (
              loadingResults
            ) : listings.length > 0 ? (
              <ListingsTable
                listings={listings}
                timeslots={timeslots}
                listingMenuOpen={listingMenuOpen}
                actionsInProgressListingId={openingListing || closingListing || discardListing}
                onToggleMenu={onToggleMenu}
                onCloseListing={onCloseListing}
                onOpenListing={onOpenListing}
                onDiscardListing={onDiscardListing}
                openingErrorListingId={openingErrorListingId}
                closingErrorListingId={closingErrorListingId}
                discardErrorListingId={discardErrorListingId}
              />
            ) : (
              noResults
            )}
            {paginationLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <LandingFooter isAuthenticated={isAuthenticated} />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ManageListingsPageComponent.defaultProps = {
  isAuthenticated: false,
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(object),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onDiscardListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  discardListing: shape({ uuid: string.isRequired }),
  discardListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    listings,
    timeslots,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    discardListing,
    discardListingError,
  } = state.ManageListingsPage;

  const { currentUser } = state.user;

  return {
    isAuthenticated,
    listings,
    timeslots,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    discardListing,
    discardListingError,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onDiscardListing: listingId => dispatch(discardListing(listingId)),
  onGetListings: params => dispatch(manualLoadData(params)),
});

const ManageListingsPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageListingsPageComponent);

export default ManageListingsPage;
